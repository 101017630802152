// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import {apiBaseURL} from "@utility/http"


export const getAllOrg = createAsyncThunk('appVolunteer/getAllOrg', async (params, {rejectWithValue}) => {
    const response = await axios.get(`${apiBaseURL}Organizations/GetAll`, {params}).then(response => {
        // console.log("resinner---", response)
        return response
    }).catch(err => {
        // console.log(err, "error---")
        if (!err) {
            throw err
        }
        rejectWithValue(err)
    })
    // console.log("response ----", response)
    return {data: response, params}
})
export const deleteOrg = createAsyncThunk('appVolunteer/deleteOrg', async (id, {
    dispatch,
    getState,
    rejectWithValue
}) => {

    try {
        const response = await axios.delete(`${apiBaseURL}Organizations/${id}`)
        if (response.data.statusCode === 200) {
            await dispatch(getAllOrg({...getState().organizations.params}))
        }
        return response
    } catch (err) {
        if (!err) {
            throw err
        }
        rejectWithValue(err)
    }

})

export const appOrgSlice = createSlice({
    name: 'appOrg',
    initialState: {
        allOrgs: [],
        loading: true,
        error: {},
        params: {
            PageIndex: 1,
            PageSize: 10
        }
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getAllOrg.fulfilled, (state, action) => {
                // console.log("action payload", action.payload)
                state.params = action.payload.params
                state.allOrgs = action.payload.data.data
                state.loading = false
            })
            .addCase(getAllOrg.rejected, (state, action) => {
                // console.log("getall rejected--", action.payload)
                state.error = action.payload
            })
            .addCase(deleteOrg.fulfilled, (state) => {
                // console.log("delete action", action.payload)
                state.loading = false
            })
            .addCase(deleteOrg.rejected, (state, action) => {
                // console.log("delete rejected---", action.payload)
                state.error = action.payload
            })

    }
})

export default appOrgSlice.reducer
