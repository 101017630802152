import axios from "axios"
import JwtService from "@src/@core/auth/jwt/jwtService"
import toast from "react-hot-toast"
// import {redirect} from "react-router-dom"
// import {createMemoryHistory} from "history"

// const history = createMemoryHistory()
const axiosInstance = axios.create()
axiosInstance.interceptors.request.use(
    (config) => {
        const jwt = new JwtService()
        const accessToken = jwt.getToken()
        config.headers.common.Authorization = `${jwt.jwtConfig.tokenType} ${accessToken}`
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)
axiosInstance.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        console.log("rejected --", error)
        if (error.response.status === 401) {
            localStorage.removeItem("userData")
            // history.push("/login")
            // redirect("/login")
            // console.log(true)
            toast.error("Your Session has Expired! Please Login Again.")

            // return redirect("/login")

            window.location.assign("/login")
        }
        return Promise.reject(error)
    })


export default axiosInstance