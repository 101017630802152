// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import {apiBaseURL} from "@utility/http"
import axiosInstance from "@utility/http/unAuth"

export const getAllVolunteers = createAsyncThunk('appVolunteer/getAllVolunteers', async params => {
    // console.log("volunteer params", params)
    const response = await axiosInstance.get(`${apiBaseURL}VolunteerRequest/GetAll`, {params})
    // console.log("volunteer Response", response)

    return {data: response.data, params}
})
export const getPendingVolunteersReq = createAsyncThunk('appVolunteer/getPendingVolunteersReq', async (params, {rejectWithValue}) => {
    try {
        const response = await axiosInstance.get(`${apiBaseURL}VolunteerRequest/GetAll`, {params})
        // console.log("pending request Response", response)
        return {data: response.data, params}
    } catch (err) {
        // return Promise.reject(err)
        console.log("error in getpending req", err)
        if (error.response.status === 401) {
            console.log("error in interceptor", error)
            // dispatch something to your store
        }
        // if (!err.response) {
        //   throw err
        // }
        //
        return rejectWithValue(err.response)
    }

})
export const approveOrReject = createAsyncThunk('appVolunteer/approveOrReject', async (data, {dispatch, getState}) => {
    const response = await axios.put(`${apiBaseURL}VolunteerRequest/ApproveVolunteerRequest`, {...data})
    // console.log("approve Or Reject Response", response)
    await dispatch(getAllVolunteers({...getState().volunteers.allVolunteersRequest.params}))
    await dispatch(getPendingVolunteersReq({...getState().volunteers.params, status: "Pending"}))
    return {data: response.data, params}
})

export const appVolunteersSlice = createSlice({
  name: 'appVolunteer',
  initialState: {
      allVolunteersRequest: {
          data: [],
          params: {
              PageIndex: 1,
              PageSize: 10
          }
      },
      volunteerPendingRequest: [],
      loading: true,
      error: null,
      params: {
          PageIndex: 1,
          PageSize: 10
      }
  },
  reducers: {},
  extraReducers: builder => {
      builder
          .addCase(getAllVolunteers.fulfilled, (state, action) => {
              console.log("payload", action.payload)
              // state.params = action.payload.params
              state.allVolunteersRequest.data = action.payload.data
              state.allVolunteersRequest.params.PageSize = action.payload.data?.misc?.total
              state.loading = false
          })
          .addCase(getPendingVolunteersReq.pending, (state) => {
              // state.loading = true
              state.error = null
          })
          .addCase(getPendingVolunteersReq.fulfilled, (state, action) => {
              // console.log("payload pending", action.payload.data)
              state.volunteerPendingRequest = action.payload.data
              state.error = null
              state.loading = false
          })
          .addCase(getPendingVolunteersReq.rejected, (state, action) => {
              console.log("payload rejected", action)
              state.error = action.payload
              state.loading = false
          })

  }
})

export default appVolunteersSlice.reducer
