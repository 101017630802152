/* eslint-disable no-unused-vars */
// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

// ** Axios Imports
import {apiBaseURL} from "@utility/http"
import axiosInstance from "@utility/http/unAuth"

const data = {
    boards: [
        {
            id: `Pending`,
            name: 'Pending'
        },
        {
            id: `InProgress`,
            name: 'InProgress'
        },
        {
            id: `Completed`,
            name: 'Completed'
        }
    ]
}
// ** Fetch Projects

export const fetchProjects = createAsyncThunk('appKanban/getProjects', async params => {

    const response = await axiosInstance.get(`${apiBaseURL}Projects/GetAll`, {params})

    // console.log("project List ---", params, response)
    return {data: response.data, params}
})

// ** Fetch Boards
export const fetchTasks = createAsyncThunk('appKanban/fetchTasks', async (params) => {
    // toLogin()
    // console.log("fetch tasks params--", params)
    const response = await axiosInstance.get(`${apiBaseURL}Tasks/GetAllTaskOrdering`, {params})

    // console.log("fetched Task", response)
    return {response, params}
})

export const searchTasks = createAsyncThunk('appKanban/searchTasks', async (data) => {
    return data
})

export const updateTask = createAsyncThunk('appKanban/updateTask', async (data, {dispatch, getState}) => {
    // console.log("update Task data---", data)
    const response = await axiosInstance.put(`${apiBaseURL}Tasks`, {...data})
    await dispatch(fetchTasks({...getState().tasks.params}))

    return response.data
})
export const updateTaskStatus = createAsyncThunk('appKanban/updateTaskStatus', async (params, {dispatch, getState}) => {
    // console.log("params status change----", params)
    const response = await axiosInstance.put(`${apiBaseURL}Tasks/StatusUpdate`, null, {params})
    await dispatch(fetchTasks({...getState().tasks.params}))
    // console.log("updateStatus response", response)
    return {response: response.data, params}
})
export const updateSubtaskStatus = createAsyncThunk('appKanban/updateSubtaskStatus', async (params, {
    dispatch,
    getState
}) => {
    const response = await axiosInstance.put(`${apiBaseURL}Tasks/StatusUpdate`, null, {params})
    await dispatch(fetchTasks({...getState().tasks.params}))
    return response.data
})


export const addTask = createAsyncThunk('appKanban/addTask', async (data, {dispatch, getState}) => {
    // console.log("add Task Data post", data)
    const response = await axiosInstance.post(`${apiBaseURL}Tasks`, {...data})
    // console.log("added Task response", response)
    await dispatch(fetchTasks({...getState().tasks.params}))

    return response
})
export const addSubtask = createAsyncThunk('appKanban/addSubtask', async (data, {dispatch, getState}) => {
    // console.log("subtask Task data", data)
    const response = await axiosInstance.post(`${apiBaseURL}Tasks`, {...data})
    await dispatch(fetchTasks({...getState().tasks.params}))
    // console.log("subtask response", response)
    return response
})

export const deleteTask = createAsyncThunk('appKanban/clearTasks', async (id, {dispatch, getState}) => {
    const response = await axiosInstance.delete(`${apiBaseURL}Tasks/${id}`)
    await dispatch(fetchTasks({...getState().tasks.params}))

    return response
})
export const fetchOrderedTasks = createAsyncThunk('appKanban/fetchOrderedTasks', async (params) => {
    // console.log("fetch tasks params--", params)
    const response = await axiosInstance.get(`${apiBaseURL}Tasks/GetAllTaskOrdering`, {params})

    // console.log("getall ordered Tasks response", response.data.data)
    return {response, params}
})
export const reorderTasks = createAsyncThunk('appKanban/reorderTasks', async (data, {dispatch, getState}) => {
    try {
        // console.log("reorderTask request data---", data)
        const response = await axiosInstance.post(`${apiBaseURL}Tasks/TaskOrdering`, data.data)
        await dispatch(fetchOrderedTasks({...getState().tasks.params}))
        // console.log("reorder task response", response)
        return {response, board: data.status}
    } catch (e) {
        console.log("e", e)
    }
})

export const appKanbanSlice = createSlice({
    name: 'tasks',
    initialState: {
        ...data,
        loading: true,
        projectsLoading: true,
        inProgressLoading: true,
        pendingLoading: true,
        completedLoading: true,
        isLead: false,
        projects: [],
        allTasks: [],
        selectedTask: null,
        params: {
            PageIndex: 1,
            PageSize: 10000

        }
    },
    reducers: {
        handleSelectTask: (state, action) => {
            state.selectedTask = action.payload
        },
        setLead: (state, action) => {
            state.isLead = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchTasks.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchTasks.fulfilled, (state, action) => {
                // console.log("fetch task fulfilled", action.payload)
                state.allTasks = action.payload.response.data.data
                state.params = action.payload.params
                state.loading = false
                state.pendingLoading = false
                state.inProgressLoading = false
                state.completedLoading = false

            })
            .addCase(fetchTasks.rejected, (state) => {
                state.loading = false
            })
            .addCase(fetchProjects.pending, (state) => {
                state.projectsLoading = true
            })
            .addCase(fetchProjects.fulfilled, (state, action) => {
                // state.params = action.payload.params
                state.projects = action.payload.data
                state.projectsLoading = false
            })
            .addCase(fetchProjects.rejected, (state) => {
                state.projectsLoading = false
            })
            // .addCase(updateTask.fulfilled, (state, action) => {
            //   // console.log("update Task fulfilled", action.payload)
            // })
            .addCase(fetchOrderedTasks.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchOrderedTasks.fulfilled, (state, action) => {
                // console.log("action", action.payload.response.data.data)
                state.allTasks = action.payload.response.data.data
            })
            .addCase(updateTask.pending, (state) => {
                state.pendingLoading = true
                state.inProgressLoading = true
                state.completedLoading = true
            })
            .addCase(updateTask.fulfilled, (state) => {
                state.pendingLoading = false
                state.inProgressLoading = false
                state.completedLoading = false
            })

            .addCase(reorderTasks.pending, (state, action) => {
                console.log("action reorder pending", action.meta.arg.status)
                if (action.meta.arg.status.toLowerCase() === "pending") {
                    state.pendingLoading = true
                    state.inProgressLoading = false
                    state.completedLoading = false
                } else if (action.meta.arg.status.toLowerCase() === "inprogress") {
                    state.pendingLoading = false
                    state.inProgressLoading = true
                    state.completedLoading = false
                } else if (action.meta.arg.status.toLowerCase() === "completed") {
                    state.pendingLoading = false
                    state.inProgressLoading = false
                    state.completedLoading = true
                } else {
                    state.pendingLoading = false
                    state.inProgressLoading = false
                    state.completedLoading = false
                }
                // state.loading = true
            })
            .addCase(reorderTasks.fulfilled, (state, action) => {
                console.log("action reorder fullfilled", action)
                state.pendingLoading = false
                state.inProgressLoading = false
                state.completedLoading = false
                state.loading = false
            })


    }
})

export const {handleSelectTask, setLead} = appKanbanSlice.actions

export default appKanbanSlice.reducer
