// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
// eslint-disable-next-line no-unused-vars
import axios from 'axios'
import {apiBaseURL} from "@utility/http"
import axiosInstance from "@utility/http/unAuth"

export const getOptions = createAsyncThunk('appVolunteer/getOptions', async () => {
    try {
        const priorityType = await axiosInstance.get(`${apiBaseURL}Home/Types/priorityType`)
        const sdgType = await axiosInstance.get(`${apiBaseURL}Home/Types/sdgtype`)
        const taskType = await axiosInstance.get(`${apiBaseURL}Home/Types/tasktype`)
        const severityType = await axiosInstance.get(`${apiBaseURL}Home/Types/severitytype`)
        // console.log("options Response", priorityType, sdgType, taskType, severityType)
        return {priorityType, sdgType, taskType, severityType}
    } catch (e) {
        // console.log(e)
        return e
    }

})

export const appOptionsSlice = createSlice({
    name: 'appOptions',
    initialState: {
        priorityTypeRes: [],
        sdgTypeRes: [],
        taskTypeRes: [],
        severityTypeRes: [],
        loading: true,
        error: null
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getOptions.pending, (state) => {
                state.loading = true
            })
            .addCase(getOptions.fulfilled, (state, action) => {
                // console.log("payload options", action.payload)
                state.priorityTypeRes = action.payload.priorityType.data
                state.sdgTypeRes = action.payload.sdgType.data
                state.taskTypeRes = action.payload.taskType.data
                state.severityTypeRes = action.payload.severityType.data
                state.error = null
                state.loading = false
            })
            .addCase(getOptions.rejected, (state, action) => {
                state.error = action.payload
                state.loading = false
            })

    }
})

export default appOptionsSlice.reducer
