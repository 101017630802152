import getDefaultInstance from "@src/utility/http/service"
import "./service"

export const apiBaseURL = process.env.REACT_APP_BASEAPIURL

console.log(getDefaultInstance)
const s = getDefaultInstance === undefined || getDefaultInstance === null ? {} : getDefaultInstance("en")
const def = {
  _delete: s.delete,
  _get: s.get,
  _post: s.post,
  _put: s.put,
  _request: s.request
}

export const _delete = def._delete
export const _get = def._get
export const _post = def._post
export const _put = def._put
export const _request = def._request

