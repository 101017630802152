import {Ability, AbilityBuilder} from '@casl/ability'
import {initialAbility} from './initialAbility'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem('userData'))
const existingAbility = userData ? userData.ability : null
// const existingAbility = userData ? (userData.ability ? userData.ability : [
//   {
//     action: 'read',
//     subject: 'Dashboard'
//   }
// ]) : null

export function updateAbility(ability, user) {
  const {rules} = new AbilityBuilder(Ability)
  rules.push(...user.ability)
  ability.update(rules)
}

// export default function defineAbilityFor(user) {
//   const { can, cannot, build } = new AbilityBuilder(Ability);
//
//   if (user.isAdmin) {
//     can('manage', 'all'); // read-write access to everything
//   } else {
//     can('read', 'all') // read-only access to everything
//   }
//
//   cannot('delete', 'Post', { published: true });
//
//   return build();
// }

export default new Ability(existingAbility || initialAbility)
