import axios from "axios"
import {apiBaseURL} from "@src/utility/http/index"
import JwtService from "@src/@core/auth/jwt/jwtService"

const getDefaultInstance = (lang = "en") => {
  return axios.create({
    baseURL: `${apiBaseURL}`,
    headers: {lang},
    timeout: 30000,
    transformRequest: [
      (data, headers) => {
        const jwt = new JwtService()
        const accessToken = jwt.getToken()

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          headers.common.Authorization = `${jwt.jwtConfig.tokenType} ${accessToken}`
        }
        return data
      }, ...axios.defaults.transformRequest
    ],
    transformResponse: []
  })
}
export default getDefaultInstance
