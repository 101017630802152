import {createSlice} from '@reduxjs/toolkit'

export const expireSession = createSlice({
    name: 'session',
    initialState: {
        error: null
    },
    reducers: {
        expSession: (state, action) => {
            console.log("error expire session", action.payload)
            state.error += action.payload
        }
    }
})

export const {expSession} = expireSession.actions

export const selectCount = (state) => state.session.error

export default expireSession.reducer