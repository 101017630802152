// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import {apiBaseURL} from "@utility/http"

const data = {
    products: [
        {
            id: 1,
            name: 'Associate External Relations and Reporting Officer',
            duration: "9 months",
            sdgType: {
                name: "GOAL 17: Partnerships to achieve the Goal",
                value: "SDG17",
                color: "#19486A"
            },
            taskType: {
                name: "Physical Work",
                value: "TK1",
                color: "primary"
            },
            description:
                `Le Haut-Commissariat des Nations Unies pour les réfugiés (HCR) a pour mandat de diriger et de coordonner l’action internationale visant à protéger les réfugiés. Il a pour but premier de sauvegarder les droits et le bien être des réfugiés. Il met en œuvre ses programmes de protection dans le monde entier et sensibilise le grand public à la situation des personnes réfugiées dans le monde.`,
            noOfAssignments: 1,
            organizationName: "UN-HABITAT POLICY, LEGISLATION, GOVERNANCE SECTION",
            organizationLogo: "",
            country: "Pakistan",
            created: "2023-02-04T11:00:00",

            slug: '3-year-unlimited-cloud-storage-service-activation-card-other-1',
            brand: 'VicTsing',
            price: 10.99,
            image: "https://camo.githubusercontent.com/826133902a4cb45a0c1618a361649568f4da6de5c33b0417549cb2f04e7e2918/68747470733a2f2f7261776769742e636f6d2f6a616e74696d6f6e2f7376672d706c616365686f6c6465722f6d61737465722f6578616d706c652e737667",
            hasFreeShipping: true,
            rating: 2
        }, {
            id: 2,
            name: 'Reviewing a text document, translating some graphs into Kazakh, redrawing the missing charts',
            duration: "1 Week",
            sdgType: {
                name: "GOAL 13: Partnerships to achieve the Goal",
                value: "SDG13",
                color: "#19486A"
            },
            taskType: {
                name: "Virtual Work",
                value: "TK1",
                color: "success"
            },
            description:
                `Reviewing a text document, translating some graphs into Kazakh, redrawing the missing charts`,
            noOfAssignments: 1,
            organizationName: "UNDP",
            organizationLogo: "",
            country: "Kazakhstan",

            slug: '3-year-unlimited-cloud-storage-service-activation-card-other-1',
            brand: 'VicTsing',
            price: 10.99,
            image: "https://camo.githubusercontent.com/826133902a4cb45a0c1618a361649568f4da6de5c33b0417549cb2f04e7e2918/68747470733a2f2f7261776769742e636f6d2f6a616e74696d6f6e2f7376672d706c616365686f6c6465722f6d61737465722f6578616d706c652e737667",
            hasFreeShipping: true,
            rating: 2
        }
    ],
    userWishlist: [
        {id: 1, productId: 26},
        {id: 2, productId: 23}
    ],
    userCart: [
        {id: 1, productId: 27, qty: 1},
        {id: 2, productId: 21, qty: 1},
        {id: 3, productId: 26, qty: 1},
        {id: 4, productId: 25, qty: 1},
        {id: 5, productId: 23, qty: 1}
    ]
}

export const getOrgProjects = createAsyncThunk('orgProjects/getProjects', async params => {
    // console.log("project--- async reducer params", params)
    const response = await axios.get(`${apiBaseURL}Projects/GetAll`, {params})
    console.log("projects by filtration", params, response)

    return {data: response.data, params}
})

export const findProjNameExist = createAsyncThunk('Projects/CheckName', async params => {
    // console.log("project--- async reducer params", params)
    const response = await axios.get(`${apiBaseURL}Projects/CheckName`, {params})
    console.log("name available?", params, response.data)

    return {data: response.data, params}
})

export const appOrgProjectsSlice = createSlice({
    name: 'orgProjects',
    initialState: {
        projects: [],
        projectsByFiltration: [],
        loading: true,
        // cart: [],
        params: {
            PageIndex: 1,
            PageSize: 10,
            offSet: 1,
            Priority: '',
            WorkType: "",
            SDG: "",
            InitialWorkingHours: null,
            FinalWorkingHours: null,
            isAscending: true
        }
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getOrgProjects.pending, (state) => {
                state.loading = true
            })
            .addCase(getOrgProjects.fulfilled, (state, action) => {
                console.log("Projects fullfiled0", action.payload)
                state.params = action.payload.params
                state.projects = action.payload.data
                state.loading = false
            })
            .addCase(getOrgProjects.rejected, (state) => {
                state.loading = false
            })
            .addCase(findProjNameExist.pending, (state) => {
                state.loading = true
            })
            .addCase(findProjNameExist.fulfilled, (state, action) => {
                console.log("Projects fullfiled0", action.payload)
                state.params = action.payload.params
                state.nameExistence = action.payload.data
                state.loading = false
            })
            .addCase(findProjNameExist.rejected, (state) => {
                state.loading = false
            })
    }
})

export default appOrgProjectsSlice.reducer
export {data}
