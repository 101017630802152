// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import tasks from "@src/views/tasks/store"
import projects from '@src/views/home/store'
import volunteers from '@src/views/volunteers/store'
import users from '@src/views/users/store'
import organizations from '@src/views/organizations/store'
import orgProjects from '@src/views/projects/store'
import options from "@utility/common/Options"
import expireSession from "@utility/common/expireSessionError"


const rootReducer = {
    navbar,
    layout,
    tasks,
    projects,
    volunteers,
    users,
    organizations,
    options,
    expireSession,
    orgProjects
}

export default rootReducer
