// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import {apiBaseURL} from "@utility/http"


export const getAllUsers = createAsyncThunk('appVolunteer/getAllUsers', async params => {
    try {
        const response = await axios.get(`${apiBaseURL}AppUser/GetAll`, {params})
        return {data: response.data, params}
    } catch (err) {
        return err
    }
})
export const deleteUser = createAsyncThunk('appVolunteer/deleteUsers', async (id, {dispatch, getState}) => {

    try {
        const response = await axios.delete(`${apiBaseURL}AppUser/${id}`)
        if (response.data.statusCode === 200) {
            await dispatch(getAllUsers({...getState().users.params}))
        }
        return response
    } catch (err) {
        console.log("error", err)
        return err
    }

})

export const appUsersSlice = createSlice({
    name: 'appUsers',
    initialState: {
        allUsers: [],
        loading: true,
        error: {},
        params: {
            PageIndex: 1,
            PageSize: 10
        }
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getAllUsers.fulfilled, (state, action) => {
                // console.log("payload users", action.payload)
                state.params = action.payload.params
                state.allUsers = action.payload.data
                state.loading = false
            })
            .addCase(getAllUsers.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                console.log("delete action", action.payload)
            })
            .addCase(deleteUser.rejected, (state, action) => {
                console.log("delete rejected", action.payload)
            })

    }
})

export default appUsersSlice.reducer
